import React from "react";
import { jackColors } from "../../../assets/colors";
import imgSrc from "../../../assets/images/balance-dashboard.svg";
import bigImg from "../../../assets/images/balance-dashboard-big.svg";
import { TopUpModal, useModalHook } from "../../../components/Modals";
import WithdrawalModal from "../../../components/Modals/WithdrawModal/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { DashboardBannersButtons, DashboardTitleAndButton } from "./buttons";
import { eventsTracker } from "../../../universalFunctions/events";
import { CompanyTagCapsule } from "../../jackTransferComponents/components";
import TransferSelectionModal from "../../../modals/TransferSelectionModal";
import { J2J_EVENT_CLICK_TRANSFER_BALANCE } from "../../jackTransferComponents/constants";
import { COMPANY_BALANCE_WIDTH } from "./parent";
import { InputAmountModal } from "../../homeComponents/topup/modals";

export const DashboardBalance = ({ isBig }) => {
  const { isDrafterSendMoney, isDrafterBalanceManagement } = getUserRole();

  const canWithdraw = isDrafterBalanceManagement;
  const canSendMoney = isDrafterSendMoney || isDrafterBalanceManagement;

  const array = [
    { label: "Top Up", value: "top_up", iconName: "top up" },
    canWithdraw && {
      label: "Withdraw",
      value: "withdraw",
      iconName: "withdraw",
    },
    canSendMoney && {
      label: "Transfer",
      value: "send_money",
      iconName: "paper-plane",
    },
  ].filter((item) => item);

  const { isOpen, toggle } = useModalHook();
  const { isOpen: isOpenTopUp, toggle: toggleTopUp } = useModalHook();
  const { isOpen: isOpenSendMoney, toggle: toggleSendMoney } = useModalHook();

  const handleClick = (value) => {
    const isWithdraw = value == "withdraw";
    const isTopup = value === "top_up";
    const isSendMoney = value === "send_money";

    if (isWithdraw) {
      eventsTracker("open_withdraw_modal", { page_title: "dashboard" });
      return toggle();
    }
    if (isTopup) {
      eventsTracker("to_topup", { page_title: "dashboard" });
      return toggleTopUp();
    }
    if (isSendMoney) {
      eventsTracker(J2J_EVENT_CLICK_TRANSFER_BALANCE, {
        parameters: { page_title: "dashboard" },
        value: "dashboard",
      });
      return toggleSendMoney();
    }
  };

  const { user, refetchUser, userLoading } = useGetAuth();
  const { balance } = user?.mainBalance || {};
  const getBalance = `IDR ${formatCurrencyNoDecimal(balance)}`;

  // DEMO PURPOSE
  const afterTopup = () => {
    refetchUser();
    if (!userLoading) return toggleTopUp();
  };

  const buttons = (
    <>
      <DashboardBannersButtons
        array={array}
        onClick={handleClick}
        isReversedColors
      />
      {/* <TopUpModal modal={isOpenTopUp} toggle={toggleTopUp} /> */}

      {/* Demo Purpose */}
      <InputAmountModal
        isOpen={isOpenTopUp}
        toggle={toggleTopUp}
        setAfterSuccess={afterTopup}
      />
      <WithdrawalModal isOpen={isOpen} toggle={toggle} />
      <TransferSelectionModal
        isOpen={isOpenSendMoney}
        toggle={toggleSendMoney}
        setAf
      />
    </>
  );

  if (true)
    return (
      <div
        className="w-100 h-100 d-flex justify-content-between"
        style={{
          flexDirection: "column",
          borderRadius: 8,
          backgroundColor: "white",
          position: "relative",
          padding: 16,
          paddingTop: 12,
          height: 216,
          minHeight: 216,
          maxWidth: COMPANY_BALANCE_WIDTH,
          minWidth: COMPANY_BALANCE_WIDTH,
          border: `1px solid ${jackColors.greyE6}`,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <GothamRegular
              className="font14"
              style={{ color: jackColors.neutral800 }}
            >
              Company Balance
            </GothamRegular>
            {isDrafterBalanceManagement && <CompanyTagCapsule />}
          </div>
          <GothamMedium
            className="font20"
            style={{ color: jackColors.black34 }}
          >
            {getBalance}
          </GothamMedium>
          {/* <img
            src={bigImg}
            style={{
              position: "absolute",
              right: 0,
              top: 3,
            }}
          /> */}
        </div>
        {buttons}
      </div>
    );

  return (
    <div
      className="w-100 d-flex justify-content-between align-items-center"
      style={{
        backgroundColor: "black",
        position: "relative",
        padding: 16,
        paddingRight: 12,
        paddingLeft: 12,
        borderRadius: 4,
        height: 96,
      }}
    >
      <GothamRegular
        style={{
          color: jackColors.greyE6,
          position: "absolute",
          top: 16,
          left: 12,
        }}
        className="font12"
      >
        Company Balance
      </GothamRegular>
      <GothamMedium className="font16" style={{ color: jackColors.greyE6 }}>
        {getBalance}
      </GothamMedium>
      {buttons}
      <img
        src={imgSrc}
        style={{
          right: 0,
          top: 0,
          position: "absolute",
        }}
      />
    </div>
  );
};
