import React, { useEffect } from "react";
import { jackColors } from "../../assets/colors";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { useGetAuth } from "../../contexts/AuthContext";
import { useHeader } from "../../contexts/Layout/parent";
import SuspendedBanner from "../subscriptionsJackComponents/general-components/SuspendedBanner/parent";
import { useSubscriptionUsage } from "../subscriptionsJackComponents/logics/general-hooks";

export const DashboardHeader = () => {
  const { isSuspended } = useSubscriptionUsage();

  const { setHeader } = useHeader();

  const { user } = useGetAuth();
  const { name, email } = user || {};
  const nameOrEmail = `Demo ${(name || "").split(" ")[0]}` || email;

  useEffect(() => {
    setHeader({
      type: "createAndBell",
    });
  }, []);

  return (
    <div style={{ borderBottom: `1px solid ${jackColors.greyE6}` }}>
      <div
        className="d-flex align-items-center"
        style={{ marginLeft: 32, paddingBottom: isSuspended ? 0 : 63 }}
      >
        <GothamMedium className="font24">Dashboard</GothamMedium>
        <GothamRegular
          className="font16"
          style={{ color: jackColors.grey90, marginLeft: 16 }}
        >
          Welcome back, {nameOrEmail}!
        </GothamRegular>
      </div>
      {isSuspended && <SuspendedBanner />}
    </div>
  );
};
