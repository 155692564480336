import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { useModalHook } from "../../components/Modals";
import { GothamRegular } from "../../components/Text";
import { getUserRole, useGetAuth } from "../../contexts/AuthContext";
import SwitchRoleModal from "../productDemoComponents/components/SwitchRoleModal";
import { SchedulePaymentDashboard } from "../scheduledPaymentComponents/dashboardComponent";
import { TransactionsTab } from "../transactionsComponents/parent";
import { DashboardBanners } from "./banners/parent";
import { DashboardChart } from "./chart";
import { DashboardHeader } from "./header";
import { ScheduledTransactions } from "./scheduled";
import { JackIcons } from "../../assets/jackIcons/parent";
import FloatingButtonDemo from "../productDemoComponents/components/FloatingButtonDemo";
import useGuidedTourBuilder from "../productDemoComponents/builders";
import {
  APPROVAL_MODULE,
  DEMO_MODULE,
  TASK_MODULE,
  USERS_MODULE,
  WORKFLOW_MODULE,
} from "../productDemoComponents/constants";
import { useGuidedTour } from "../../contexts/GuidedTourContext";
import { useDemo } from "../../contexts/DemoContext";

export const DashboardPage = () => {
  const { isEmployee } = getUserRole();
  const { user, refetchUser } = useGetAuth();

  const isBlocked = user?.status === "blocked";

  const { isOpen, toggle } = useModalHook(
    localStorage.getItem("popup") == "true"
  );

  const { query, push, replace, isReady } = useRouter();
  const { pageType } = query;
  const isScheduledTransaction = pageType == "scheduled_transactions";

  useEffect(() => {
    refetchUser();
  }, []);

  // DEMO PURPOSE
  const { id: currentUserId } = user ?? {};
  const userIdLocal = localStorage.getItem("switch");
  const shouldHideModal = localStorage.getItem("hide-switch");
  const { isOpen: isOpenSwitchRole, toggle: toggleSwitchRole } = useModalHook();
  const { isOpen: isOpenDemoGuidance, toggle: toggleDemoGuidance } =
    useModalHook();

  useEffect(() => {
    if (!userIdLocal) return;
    if (!!shouldHideModal) return;
    if (userIdLocal == currentUserId) {
      setTimeout(() => {
        toggleSwitchRole();
      }, 1500);
    }
    localStorage.setItem("hide-switch", true);
  }, [userIdLocal, currentUserId, shouldHideModal]);

  // DEMO GUIDANCE
  const [page, setPage] = useState(null);
  const guidedTourProps = useGuidedTourBuilder({ module: DEMO_MODULE, page });
  const { startTour, stopTour } = useGuidedTour({
    ...guidedTourProps,
    props: { scrollOffset: 300 },
  });
  const { setGuidanceState } = useDemo();

  useEffect(() => {
    setGuidanceState((prev) => ({
      ...prev,
      start: startTour,
      stop: stopTour,
    }));
  }, []);

  const needsRoutingModules = [WORKFLOW_MODULE, TASK_MODULE, USERS_MODULE];
  const handleRouting = (item) => {
    let path = "";
    let query = {};
    switch (item) {
      case WORKFLOW_MODULE:
        path = "/workflow";
        break;
      case TASK_MODULE:
        setGuidanceState((prev) => ({
          ...prev,
          guidedTask: true,
        }));
        return;
      case USERS_MODULE:
        path = "/people-management";
        break;
      default:
        break;
    }
    push(path && path, { query });
  };

  useEffect(() => {
    if (!page) return;
    if (needsRoutingModules.includes(page)) return;
    setTimeout(() => {
      startTour();
    }, 700);
  }, [page]);

  if (isScheduledTransaction) return <ScheduledTransactions />;
  return (
    <>
      <DashboardHeader />
      {!isBlocked && (
        <Fragment>
          <DashboardBanners />
          {!isEmployee && <SchedulePaymentDashboard />}
          {!isEmployee && <DashboardChart />}
          <GothamRegular
            className="font12"
            style={{ marginTop: 32, marginLeft: 32, marginBottom: 8 }}
          >
            Recent Transaction
          </GothamRegular>
          <TransactionsTab isRecent />
          <div style={{ height: 32 }} />
          <FloatingButtonDemo
            isOpen={isOpenDemoGuidance}
            toggle={toggleDemoGuidance}
            customOnClick={(item) => {
              setPage(item);
              if (!needsRoutingModules.includes(item)) {
                setGuidanceState((prev) => ({
                  ...prev,
                  isHelperClicked: true,
                }));
              } else {
                handleRouting(item);
              }
            }}
          />
        </Fragment>
      )}
      <SwitchRoleModal isOpen={isOpenSwitchRole} toggle={toggleSwitchRole} />

      {/* dihide for demo purpose */}
      {/* <PopUp
        isOpen={isOpen}
        toggle={() => {
          toggle();
          localStorage.setItem("popup", "false");
        }}
      /> */}
    </>
  );
};
