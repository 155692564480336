import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useCallback, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { chartIncrementation, oneBillion, oneK, oneMillion } from "./constants";
import { AreaChartTooltip } from "./tooltip";
import { jackColors } from "../../../assets/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const AreaChart = ({
  data = [], // data = [1,2,3,4]
  labels = [], // labels = [{label:'January', date:'2021-06-31'}]
}) => {
  const maxAmount = Math.max(...data);

  const [tooltipProps, setTooltipProps] = useState(false);
  const height = 180;
  const { index } = tooltipProps || {};
  const chartRef = useRef();

  const minValue = Math.min(...data);

  const tooltipFunc = useCallback((tooltipModel) => {
    const canvas = chartRef?.current?.canvas;

    const {
      opacity,
      caretX: x,
      caretY: y,
      dataPoints = [],
    } = tooltipModel?.tooltip || {};

    const { dataIndex: index } = dataPoints[0] || {};

    const hide = !opacity;

    if (!canvas) return;

    if (hide) return;

    setTooltipProps((prev) => {
      const { x: prevX } = prev || {};

      if (prevX == x) return prev;
      return { x, y, index };
    });
  });

  const datasets = [{ data }];

  const contextIndex = (context, active, inactive) => {
    const { dataIndex } = context || {};
    if (index == dataIndex) return active;
    return inactive;
  };

  return (
    <div
      style={{ position: "relative", height, marginBottom: 18 }}
      onMouseLeave={() => setTooltipProps(false)}
    >
      <Line
        data={{
          labels: labels.map(({ value }) => value),
          datasets,
        }}
        ref={chartRef}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: tooltipFunc,
            },
          },
          elements: {
            line: {
              tension: 0.4,
              borderWidth: 2,
              borderColor: jackColors.black34,
              fill: "start",
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                gradient.addColorStop(0, jackColors.black34);
                gradient.addColorStop(1, "transparent");
                return gradient;
              },
            },
            point: {
              hitRadius: 5,
              radius: 5,
              backgroundColor: (context) =>
                contextIndex(context, "white", "transparent"),
              borderColor: (context) =>
                contextIndex(context, "black", "transparent"),
            },
          },
          scales: {
            yAxis: {
              // min: minValue,
              // display: false,
              ticks: {
                callback: (value) => {
                  const is1Bil = value >= oneBillion;
                  const is1Mil = value >= oneMillion;
                  const is1K = value >= oneK;

                  if (is1Bil) return `${value / oneBillion} Bio.`;
                  if (is1Mil) return `${value / oneMillion} Mio.`;
                  if (is1K) return `${value / oneK} K.`;

                  return value;
                },
                stepSize: chartIncrementation(maxAmount),
              },
            },
            xAxis: {
              // display: false,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
      <AreaChartTooltip
        tooltipProps={tooltipProps}
        height={height}
        labels={labels}
        data={data}
      />
    </div>
  );
};
