import { Fragment, useRef } from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import pattern from "../../../../assets/jackImages/jack-pattern.png";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../components/Text";
import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { font12, font14 } from "../../../virtualAccountComponents/common/utils";
import {
  BILL_MODULE,
  INTERNATIONAL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  TASK_MODULE,
  USERS_MODULE,
  VA_MODULE,
  WORKFLOW_MODULE,
} from "../../constants";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_CLICK_DEMO_GUIDANCE,
  DEMO_PLAY_DEMO_GUIDANCE,
} from "../../constants/events";
import styles from "./styles.module.css";

const FloatingButtonDemo = ({ isOpen, toggle, customOnClick = () => {} }) => {
  const ref = useRef(null);
  const { user } = useGetAuth();
  const { roleName, isBookKeeperDemo, isEmployee } = getUserRole();

  const array = [
    {
      label: "Create Local Transfer",
      description:
        "Learn how to sends thousands of transfers in real time to any bank or e-wallet in Indonesia.",
      icon: "SendMoney",
      module: LOCAL_MODULE,
    },
    {
      label: "Create International Transfer",
      description:
        "We’ll guide you to effortlessly create international transfers to across +60 countries.",
      icon: "InternationalTransfer",
      module: INTERNATIONAL_MODULE,
    },
    {
      label: "Create Payroll Transaction",
      description:
        "Follow our simple steps to distribute and streamline your company’s payroll.",
      icon: "Payroll",
      module: PAYROLL_MODULE,
    },
    {
      label: "Create Bill Payment Request",
      description:
        "Wave good bye to manual data entry. Discover the ease of paying invoices with our OCR technology. ",
      icon: "InvoicePayment",
      module: BILL_MODULE,
    },
    {
      label: "Request Reimbursement",
      description:
        "Explore how easy it is to submit claims, get approvals, and get paid fast.",
      icon: "Reimbursement",
      module: REIMBURSEMENT_MODULE,
    },
    {
      label: "Create Virtual Account",
      description:
        "Find out how to create virtual accounts and share them to receive payments.",
      icon: "Coin",
      module: VA_MODULE,
    },
    !isBookKeeperDemo && {
      label: "Manage Task",
      description:
        "Check out how easy to approve and release payment for transactions that require your attention.",
      icon: "FactCheck",
      module: TASK_MODULE,
    },
    !(isEmployee || isBookKeeperDemo) && {
      label: "Create Approval Workflow",
      description:
        "We’ll guide you to craft approval flow that suits your company's unique needs.",
      icon: "Workflow",
      module: WORKFLOW_MODULE,
    },
    {
      label: "Manage Users & Teams",
      description: "Learn how to invite and manage users in your company. ",
      icon: "TeamManagement",
      module: USERS_MODULE,
    },
  ];
  useClickOutside({ ref, clickOutside: toggle });
  const onClickDemoGuidance = () => {
    eventsTracker(DEMO_CLICK_DEMO_GUIDANCE, { page_title: "dashboard" });
    toggle();
  };
  return (
    <Fragment>
      <button className={styles["button-outer"]} onClick={onClickDemoGuidance}>
        <JackIcons
          name={!isOpen ? "BulbFill" : "Close"}
          fill="white"
          style={{ height: "20px" }}
        />
      </button>
      <Modal
        innerRef={ref} /* backdrop={"static"} */
        backdropClassName={"transparent-backdrop"}
        isOpen={isOpen}
        toggle={toggle}
        style={{
          width: "400px",
          marginTop: "9vh",
          position: "fixed",
          right: "16px",
          // overflow: "hidden",
          background: "transparent",
        }}
      >
        <div className={styles["modal-outer"]} style={{ overflow: "hidden" }}>
          <div style={{ position: "relative" }}>
            <img
              src={pattern}
              alt="bg-pattern"
              style={{
                scale: "0.5",
                // opacity: "0.5",
                position: "absolute",
                top: "-185px",
                left: "-100px",
                zIndex: 1,
              }}
            />
          </div>
          <div className={styles["modal-header-outer"]}>
            <div className={styles["modal-header"]}>
              <GothamMedium
                className="text-white"
                style={{ fontSize: "24px", lineHeight: "32px" }}
              >
                Need guidance while exploring?
              </GothamMedium>
              <GothamRegular
                className="text-white"
                style={{ fontSize: "14px", lineHeight: "20px" }}
              >
                Check out these guided tours designed for{" "}
                <TextInlineMedium> {roleName} role. </TextInlineMedium>
                Click 'show me' to begin!
              </GothamRegular>
            </div>
          </div>
          <div className={styles["modal-body-container"]}>
            <div className={styles["modal-body"]}>
              {array?.map((item, idx) => {
                const {
                  label,
                  description,
                  icon = "PlayCircle",
                  module,
                } = item ?? {};

                const activity_type = (function () {
                  switch (module) {
                    case LOCAL_MODULE:
                      return "create_local_transfer";
                    case INTERNATIONAL_MODULE:
                      return "create_international_transfer";
                    case PAYROLL_MODULE:
                      return "create_payroll";
                    case BILL_MODULE:
                      return "create_bill_payment";
                    case REIMBURSEMENT_MODULE:
                      return "create_reimbursement";
                    case VA_MODULE:
                      return "create_va";
                    case TASK_MODULE:
                      return "task";
                    case WORKFLOW_MODULE:
                      return "workflow";
                    case USERS_MODULE:
                      return "user_and_teams";
                  }
                })();

                const onClickPlay = () => {
                  eventsTracker(DEMO_PLAY_DEMO_GUIDANCE, {
                    page_title: "dashboard",
                    activity_type,
                  });
                  customOnClick(module);
                  return toggle();
                };

                return (
                  <div key={idx} className={styles["product-card"]}>
                    <div className={styles["card-header"]}>
                      <div className={styles["card-header-left"]}>
                        <div className={styles["card-icon"]}>
                          <JackIcons
                            name={icon}
                            fill={jackColors.black34}
                            style={{ height: "20px" }}
                          />
                        </div>
                        <GothamMedium style={{ ...font14 }}>
                          {label}
                        </GothamMedium>
                      </div>
                      <ButtonJack
                        onClick={onClickPlay}
                        styleText={{ ...font12 }}
                        className={styles["btn-show-me"]}
                        rightIcon={
                          <JackIcons
                            name={"PlayCircle"}
                            fill={jackColors.black34}
                          />
                        }
                      >
                        Show me
                      </ButtonJack>
                    </div>
                    <div className={styles["card-body"]}>
                      <GothamRegular
                        style={{ ...font12, color: jackColors.grey6C }}
                      >
                        {description}
                      </GothamRegular>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default FloatingButtonDemo;
