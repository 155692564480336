import { format, isToday } from "date-fns";
import { times } from "lodash";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  idrStringFormatter,
  pluralize,
} from "../../invoiceComponents/data/formatter";
import { gmt7 } from "../../reimbursementComponents/data";
import dashboardImg from "../../../assets/images/schedule_dashboard_empty.svg";
import { useRouter } from "next/router";
export const ScheduleDashboardHeader = ({
  onClickNext,
  onClickPrev,
  onClickPaymentCycle,
  onClickSee,
  disableNext,
  disablePrev,
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ height: 40 }}
    >
      <div className="d-flex align-items-center" style={{ gap: 12 }}>
        <GothamMedium className="font20">Scheduled Payments</GothamMedium>
        <JackIcons
          name="arrow_circle_left_outline"
          fill={disablePrev ? "#BBBBC0" : "#343434"}
          onClick={onClickPrev}
          className={!disablePrev && "iconHover"}
        />
        <JackIcons
          name="arrow_circle_right_outline"
          fill={disableNext ? "#BBBBC0" : "#343434"}
          onClick={onClickNext}
          className={!disableNext && "iconHover"}
        />
      </div>
      <div className="d-flex" style={{ gap: 12 }}>
        <ButtonJack
          type="outline"
          style={{ height: 32 }}
          onClick={onClickPaymentCycle}
          leftIcon={<JackIcons name="payment_cycle_V2" fill="#343434" />}
        >
          Payment Cycle
        </ButtonJack>
        <ButtonJack
          type="outline"
          style={{ height: 32 }}
          leftIcon={<JackIcons name="calendar" fill="#343434" />}
          onClick={onClickSee}
        >
          See All Schedules
        </ButtonJack>
      </div>
    </div>
  );
};

const DelayedTitle = () => (
  <div className="d-flex-column">
    <GothamMedium
      className="font16"
      style={{ textDecorationLine: "underline" }}
    >
      Delayed Payment
    </GothamMedium>
    <GothamRegular className="font12" style={{ color: "#909098" }}>
      Payment delayed due to insufficient balance
    </GothamRegular>
  </div>
);

const DateTitle = ({ date, isToday }) => {
  const title = moment(date).format("DD MMM yyyy");
  const day = moment(date).format("dddd");
  return (
    <div className="d-flex" style={{ gap: 8 }}>
      <Avatar
        size="small"
        mainIcon={
          <JackIcons
            name="calendar"
            fill={isToday ? "#b9fc00" : "#343434"}
            style={{ transform: "scale(0.6)" }}
          />
        }
        style={{ backgroundColor: isToday ? "#343434" : "#E6E6E8" }}
      />
      <div className="d-flex-column">
        <GothamMedium
          className="font16"
          // style={{ textDecorationLine: "underline" }}
        >
          {title}
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {day} {isToday ? "(Today)" : ""}
        </GothamRegular>
      </div>
    </div>
  );
};
export const DashboardCard = (props) => {
  const {
    date,
    total_amount,
    total_transactions: totalTransactions,
    onClick,
    isLoading,
  } = props;

  const isDelayed = date == "delayed_payment";
  const iscurrentDate = isToday(new Date(gmt7(date)));

  const borderColor = isDelayed
    ? "#FCB037"
    : iscurrentDate
    ? "#343434"
    : "#BBBBC0";

  const { push } = useRouter();
  return (
    <div
      style={{
        height: 200,
        borderRadius: 8,
        padding: 12,
        borderLeft: `8px solid ${borderColor}`,
        width: "calc((100% - 64px)*0.2)",
        boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={() => {
        if (isDelayed)
          return push(
            "/dashboard/?pageType=scheduled_transactions&isDelayed=true"
          );
        onClick(date);
      }}
    >
      {isDelayed ? (
        <DelayedTitle />
      ) : (
        <DateTitle date={date} isToday={iscurrentDate} />
      )}
      <div className="d-flex-column">
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {totalTransactions} {pluralize(totalTransactions, "Transaction")}
        </GothamRegular>
        <GothamMedium>{idrStringFormatter(total_amount)}</GothamMedium>
      </div>
    </div>
  );
};

export const LoadingShimmer = () => {
  return (
    <div className="w-100">
      {times(5, (index) => (
        <Skeleton
          height={200}
          style={{
            borderRadius: 8,
            width: "calc((100% - 64px)*0.2)",
            marginRight: index !== 4 && 16,
          }}
        />
      ))}
    </div>
  );
};

const Container = ({ children, style }) => {
  return (
    <div
      className="d-flex w-100"
      style={{ gap: 16, marginTop: 16, width: "100%", ...style }}
    >
      {children}
    </div>
  );
};

export const ScheduledCardsComponent = ({
  loading,
  isEmpty,
  records,
  openModal,
}) => {
  if (loading)
    return (
      <Container>
        <LoadingShimmer />
      </Container>
    );
  if (isEmpty)
    return (
      <Container
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className=" d-flex"
          style={{
            flexDirection: "column",
            height: 204,
            alignItems: "center",
          }}
        >
          <img src={dashboardImg} />
          <GothamMedium
            style={{ width: 181, textAlign: "center", marginTop: 16 }}
          >
            All scheduled payments will be shown here.
          </GothamMedium>
        </div>
      </Container>
    );

  return (
    <Container>
      {records?.map((data) => (
        <DashboardCard {...data} onClick={openModal} />
      ))}
    </Container>
  );
};
