import React from "react";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import styles from "./styles.module.css";

export const DashboardBannersButtons = ({
  array,
  onClick,
  isReversedColors,
  isLoading,
}) => (
  <div
    style={{
      zIndex: 1,
      display: "flex",
      gap: "16px",
    }}
  >
    {array.map((item, index) => {
      const { label, value, iconName } = item;
      const isSendMoney = value === "send_money";
      if (isLoading) return <Skeleton height={30} width={100} />;
      return (
        <div
          id={isSendMoney ? "send-money-tour" : ""}
          onClick={() => onClick(value)}
          key={index}
          style={{
            display: "flex",
            cursor: "pointer",
            gap: "8px",
          }}
        >
          <div className={styles.transactionOptionButton}>
            <JackIcons
              name={iconName}
              fill={isReversedColors ? jackColors.black34 : "white"}
              style={{ width: 20, height: 20 }}
            />
            <GothamMedium
              style={{
                color: isReversedColors
                  ? jackColors.black34
                  : jackColors.greyE6,
              }}
            >
              {label}
            </GothamMedium>
          </div>
        </div>
      );
    })}
  </div>
);

const DashboardTextButton = ({ onClick, text }) =>
  !!text && (
    <div className="d-flex align-items-center" style={{ height: 32 }}>
      <GothamRegular
        style={{
          color: jackColors.greenB9,
          width: "auto",
          display: "table-cell",
        }}
        onClick={onClick}
        className="hover"
      >
        {text}
      </GothamRegular>
      <JackIcons
        name="arrow-forward-outline"
        fill={jackColors.greenB9}
        className="hover"
        onClick={onClick}
      />
    </div>
  );

export const DashboardTitleAndButton = ({
  title,
  buttonText,
  onClick,
  style,
}) => (
  <div style={{ position: "absolute", top: 20, left: 16, zIndex: 1, ...style }}>
    <GothamMedium
      className="font14"
      style={{ color: "white", marginBottom: 4 }}
    >
      {title}
    </GothamMedium>
    <DashboardTextButton text={buttonText} onClick={onClick} />
  </div>
);
