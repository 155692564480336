export const oneK = 1000;
export const oneMillion = 1000 * oneK;
export const oneBillion = 1000 * oneMillion;

export const chartIncrementation = (amount) => {
  if (amount >= oneBillion * 100) return oneBillion * 100;
  if (amount >= oneBillion * 50) return oneBillion * 50;
  if (amount >= oneBillion * 10) return oneBillion * 10;
  if (amount >= oneBillion * 5) return oneBillion * 5;
  if (amount >= oneBillion) return oneBillion;
  if (amount >= oneMillion * 500) return oneMillion * 500;
  if (amount >= oneMillion * 100) return oneMillion * 100;
  if (amount >= oneMillion * 50) return oneMillion * 50;
  if (amount >= oneMillion * 10) return oneMillion * 10;
  if (amount >= oneMillion * 5) return oneMillion * 5;
  if (amount >= oneMillion) return oneMillion;
  if (amount >= oneK * 500) return oneK * 500;
  if (amount >= oneK * 100) return oneK * 100;
  if (amount >= oneK * 50) return oneK * 50;
  if (amount >= oneK * 10) return oneK * 10;
  if (amount >= oneK * 5) return oneK * 5;
  if (amount >= oneK) return oneK;
  if (amount >= 500) return 500;
  if (amount >= 100) return 100;
  if (amount >= 50) return 50;
  if (amount >= 10) return 10;
  if (amount >= 5) return 5;
  return 1;
};
