import React from "react";
import { jackColors } from "../../../assets/colors";
import {
  getBusinessFlags,
  getUserButtonBooleans,
} from "../../../contexts/AuthContext";
import { DashboardBalance } from "./balance";
import { DashboardCards } from "./cards/parent";

export const COMPANY_BALANCE_WIDTH = 420;

const containerStyle = {
  padding: 32,
  borderBottom: `1px solid ${jackColors.greyE6}`,
  gap: "32px",
};

const Layout = ({ left, right }) => {
  return (
    <div style={containerStyle} className="d-flex">
      <div>{left}</div>
      <div style={{ width: `calc(100% - ${COMPANY_BALANCE_WIDTH - 20}px)` }}>
        {right}
      </div>
    </div>
  );
};

export const DashboardBanners = () => {
  const { canViewMainBalance, canViewCards } = getUserButtonBooleans();
  const { isEWalletActivated } = getBusinessFlags();

  const isShowAll = canViewMainBalance && isEWalletActivated;

  const showBalanceAndCards = canViewMainBalance && canViewCards;

  if (showBalanceAndCards)
    return (
      <Layout
        left={<DashboardBalance isBig />}
        // dihide for demo purpose
        // right={<DashboardCards />}
        right={null}
      />
    );

  if (canViewMainBalance) return <Layout left={<DashboardBalance isBig />} />;

  // dihide for demo purpose
  // if (canViewCards)
  //   return (
  //     <div style={{ ...containerStyle, width: "calc(50% - 16px)" }}>
  //       <DashboardCards />
  //     </div>
  //   );
  return null;
};
