import { isEmpty, times } from "lodash";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useModalHook } from "../../../components/Modals";
import { fetch } from "../../../tools/api";
import {
  DashboardCard,
  LoadingShimmer,
  ScheduleDashboardHeader,
  ScheduledCardsComponent,
} from "./component";
import { useModalHookData, useSchedulePaymentCarDashboard } from "./hooks";
import { SchedulePaymentDashboardModal } from "./modals.js";
import DemoGeneralModal from "../../productDemoComponents/components/DemoGeneralModal/index.js";

export const SchedulePaymentDashboard = () => {
  const {
    data: records,
    loading,
    refetch,
    disableNext,
    disablePrev,
    onClickNext,
    onClickPrev,
  } = useSchedulePaymentCarDashboard();

  const isRecordsEmpty = isEmpty(records);

  const {
    isOpen: modalIsOpen,
    close: closeModal,
    open: openModal,
    data: modalData,
  } = useModalHookData();

  const { isOpen: isOpenRestrict, toggle: toggleRestrict } = useModalHook();

  const { push } = useRouter();
  return (
    <div className="d-flex-column w-100" style={{ padding: "32px 32px 0px" }}>
      <ScheduleDashboardHeader
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        disableNext={disableNext}
        disablePrev={disablePrev}
        onClickPaymentCycle={() => {
          // demo purpose
          return toggleRestrict();
          push("/payment-cycle");
        }}
        onClickSee={() => {
          // demo purpose
          return toggleRestrict();
          push("/dashboard?pageType=scheduled_transactions");
        }}
      />
      <ScheduledCardsComponent
        loading={loading}
        isEmpty={isRecordsEmpty}
        records={records}
        openModal={openModal}
      />
      <SchedulePaymentDashboardModal
        isOpen={modalIsOpen}
        date={modalData}
        toggle={closeModal}
        refetchIndex={refetch}
      />
      <DemoGeneralModal isOpen={isOpenRestrict} toggle={toggleRestrict} />
    </div>
  );
};
