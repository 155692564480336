import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { IndexHeader, MonthNextPrev, ScheduledTable } from "./components";
import {
  useGetFirstDateOfMonth,
  useGetPageCount,
  useScheduleIndexData,
} from "./hooks";
import scheduledEmpty from "../../../assets/images/schedule_dashboard_empty.svg";
import { GothamMedium } from "../../../components/Text";
import { Banner } from "../../../components/Banner";

export const ScheduledTransactions = () => {
  const { range, showDaily, disablePrev, onNext, onPrev } =
    useGetFirstDateOfMonth();
  const [isUpcoming, setIsUpComing] = useState(true);
  const { push, query } = useRouter();
  const { isDelayed } = query;

  const { count, isEmpty } = useGetPageCount({ isDelayed: true });

  useEffect(() => {
    if (isDelayed) return setIsUpComing(false);
    return setIsUpComing(true);
  }, [isDelayed]);
  return (
    <div
      style={{
        padding: "0px 32px 32px",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        minHeight: "100%",
        height: "auto",
        overflowY: "scroll",
      }}
    >
      <IndexHeader
        isUpcoming={isUpcoming}
        onClickBack={() => push("/dashboard")}
        onClickDelayed={() =>
          push("/dashboard/?pageType=scheduled_transactions&isDelayed=true")
        }
        onClickUpcoming={() =>
          push("/dashboard/?pageType=scheduled_transactions")
        }
        delayedCount={count}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
        {isUpcoming ? <UpComingPage /> : <DelayedPage isEmpty={isEmpty} />}
      </div>
    </div>
  );
};

const EmptyScreen = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 344,
        display: "grid",
        placeItems: "center",
      }}
    >
      <div className="d-flex-column" style={{ gap: 16 }}>
        <img src={scheduledEmpty} />
        <GothamMedium style={{ width: 178, textAlign: "center" }}>
          All scheduled payments will be shown here.
        </GothamMedium>
      </div>
    </div>
  );
};

const UpComingPage = () => {
  const { range, showDaily, disablePrev, onNext, onPrev } =
    useGetFirstDateOfMonth();

  const { count, loading } = useGetPageCount({ range });

  const isEmpty = !loading && count == 0;

  const [bottomSheetActive, setActiveBottomSheet] = useState("");
  const canCheckToday = bottomSheetActive != "upcoming";
  const canCheckUpcoming = bottomSheetActive != "today";
  return (
    <>
      <MonthNextPrev
        date={range}
        disablePrev={disablePrev}
        onClickNext={onNext}
        onClickPrev={onPrev}
      />
      {isEmpty ? (
        <EmptyScreen />
      ) : (
        <>
          <ScheduledTable
            isToday={true}
            showDaily={showDaily}
            range={range}
            setActive={setActiveBottomSheet}
            id="today"
            canCheck={canCheckToday}
          />
          <ScheduledTable
            isToday={false}
            showDaily={showDaily}
            range={range}
            setActive={setActiveBottomSheet}
            id="upcoming"
            canCheck={canCheckUpcoming}
          />
        </>
      )}
    </>
  );
};

const DelayedPage = ({ isEmpty = false }) => {
  if (isEmpty) return <EmptyScreen />;
  return (
    <>
      <Banner
        type="warning"
        title="Payment delayed due to insufficient balance"
        msg="Please take action by selecting the transactions you want to reschedule or pay immediately."
      />
      <ScheduledTable
        isToday={false}
        isDelayed
        canCheck={true}
        id="delayed"
        setActive={() => {}}
      />
    </>
  );
};
