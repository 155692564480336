import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useGetAuth } from "../../../contexts/AuthContext";
import { fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { Avatar } from "../../Avatar";
import { ButtonJack } from "../../ButtonsJack/parent";
import CustomHoverInfo from "../../CustomHoverInfo/CustomHoverInfo";
import { NumberFieldJack } from "../../inputs/textfield";
import { useModalHook } from "../../Modals";
import PINModal from "../../PINModal/parent";
import { GothamMedium, GothamRegular } from "../../Text";
import { formatCurrency, unformatCurrency } from "../../tools";
import CategorySelection from "../../../pageComponents/categoryComponents/general/components/CategorySelection";
import { useHasRequiredCategory } from "../../../pageComponents/categoryComponents/general/hooks";
import { isEmpty } from "lodash";
import { Banner } from "../../Banner";
import { DEMO_CLICK_BACK } from "../../../pageComponents/productDemoComponents/constants/events";

const formatter = (data) => {
  const array = data?.data || [];
  const obj = array[0] || {};
  return obj;
};

const Withdraw = () => {
  const [category, setCategory] = useState({});
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);

  const hasRequiredCategory = useHasRequiredCategory({ category });

  const handleSelectCategory = (category) => {
    setCategory(category);
  };

  const { data: bankData, loading: loadingBank } = fetch({
    url: "/partner_bank_accounts?q[main_acc_eq]=true",
    formatter,
    defaultValue: {},
  });

  const { account_name, account_number, bank_name, id } = bankData ?? {};

  const useFormObj = useForm({});
  const { watch, handleSubmit } = useFormObj;

  const { user } = useGetAuth();

  const { currency, balance = 0 } = user?.mainBalance || {};

  const { push } = useRouter();

  const { isOpen: isOpenPINModal, toggle: togglePINModal } = useModalHook();

  const afterSuccess = async (data) => {
    const { id } = data?.data?.data || {};
    await push({
      pathname: "/success",
      query: { id, type: "withdraw" },
    });
  };

  const { mutation, loading: loadingWithdraw } = useMutation({
    url: "/balance_withdrawals",
    method: "post",
    afterSuccess,
  });

  const loading = loadingBank || loadingWithdraw;

  const amount = watch("amount");

  const isMoreThanBalance = Number(unformatCurrency(amount ?? "")) > balance;

  const isDisabledWithdraw =
    !amount || amount === "0" || isMoreThanBalance || !hasRequiredCategory;

  const onSubmit = () => {
    eventsTracker("submit_withdraw_amount", { page_title: "withdraw" });
    togglePINModal();
  };

  const onSubmitPIN = ({ pin }) => {
    const payload = {
      amount: String(unformatCurrency(amount)),
      partner_bank_account_id: Number(id),
      pin,
    };

    if (!isEmpty(category)) payload.category_id = category?.id;

    eventsTracker("request_withdraw", { page_title: "withdraw_enter_pin" });
    mutation(payload);
  };

  const handleBackPinModal = () => {
    if (isOpenPINModal)
      eventsTracker(DEMO_CLICK_BACK, { page_title: "withdraw_enter_pin" });
    togglePINModal();
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        <GothamMedium style={{ color: jackColors.neutral900 }}>
          Withdraw
        </GothamMedium>
        <GothamMedium
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            color: jackColors.neutral900,
          }}
        >
          How much do you want to withdraw?
        </GothamMedium>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <GothamMedium
            className="font12"
            style={{
              color: jackColors.neutral900,
            }}
          >
            Input Amount
          </GothamMedium>
          <div
            style={{
              padding: "12px 12px",
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: "4px",
            }}
          >
            <NumberFieldJack
              autoFocus
              woLabel
              isCurrency
              name="amount"
              style={{
                marginBottom: "0px",
              }}
              noBorder
              textFieldStyle={{
                border: "none",
                borderBottom: `1px solid ${jackColors.neutral500}`,
                borderRadius: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
                height: "fit-content",
              }}
              useFormObj={useFormObj}
              icon={<GothamRegular>IDR</GothamRegular>}
            />
          </div>
          <GothamRegular
            woFontColor
            style={{
              fontSize: "10px",
              lineHeight: "12px",
              color: jackColors.neutral900,
            }}
          >
            Your current balance: {currency ?? "IDR"}{" "}
            {formatCurrency(balance ?? "-")}
          </GothamRegular>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <GothamMedium
            className="font12"
            style={{
              color: jackColors.neutral900,
            }}
          >
            Send to
          </GothamMedium>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: "4px",
            }}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              {loadingBank ? (
                <div>
                  <Skeleton
                    circle
                    height={32}
                    width={32}
                    style={{ translate: "0px 4px" }}
                  />
                </div>
              ) : (
                <Avatar name={account_name} size="medium" />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {loadingBank ? (
                  <Skeleton height={16} width={200} />
                ) : (
                  <GothamMedium
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: jackColors.neutral900,
                    }}
                  >
                    {account_name}
                  </GothamMedium>
                )}
                {loadingBank ? (
                  <Skeleton height={16} width={150} />
                ) : (
                  <GothamRegular
                    woFontColor
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: jackColors.neutral700,
                    }}
                  >
                    {bank_name} - {account_number}
                  </GothamRegular>
                )}
              </div>
            </div>

            <div
              style={{ position: "relative" }}
              onMouseEnter={() => setIsHoveredInfo(true)}
              onMouseLeave={() => setIsHoveredInfo(false)}
            >
              <JackIcons
                name="info-outline"
                fill={jackColors.neutral900}
                style={{ marginTop: "-4px", scale: "0.7" }}
              />
              <CustomHoverInfo
                show={isHoveredInfo}
                style={{
                  width: "251px",
                  padding: "12px",
                  translate: "-260px -53px",
                  flexDirection: "column",
                  gap: "8px",
                }}
                translateTrianglePoint="250px 35px"
                rotateTrianglePoint="180deg"
                backgroundColor="white"
              >
                <GothamMedium style={{ color: jackColors.neutral900 }}>
                  To change bank details
                </GothamMedium>
                <GothamRegular
                  woFontColor
                  className="font12"
                  style={{ color: jackColors.neutral800 }}
                >
                  Please contact our account manager to change your destination
                  bank details
                </GothamRegular>
              </CustomHoverInfo>
            </div>
          </div>

          <CategorySelection
            selectedCategory={category}
            style={{ margin: 0, marginTop: "32px" }}
            onSelect={handleSelectCategory}
          />
        </div>
      </div>

      <div
        style={{
          padding: "20px",
          borderTop: `1px solid ${jackColors.neutral500}`,
          width: "calc(100% + 32px)",
          translate: "-16px 20px",
        }}
      >
        <Banner
          bannerStyle={{ marginBottom: "16px" }}
          msg={
            "Since you’re in Jack Demo, this are for practice purpose only and no actual funds will be processed."
          }
        />
        <ButtonJack
          style={{ width: "100%" }}
          isLoading={loading}
          disabled={isDisabledWithdraw}
        >
          Withdraw
        </ButtonJack>
      </div>
      <PINModal
        isOpen={isOpenPINModal}
        isLoading={loadingWithdraw}
        onBack={handleBackPinModal}
        toggle={handleBackPinModal}
        onSubmit={onSubmitPIN}
      />
    </form>
  );
};

export default Withdraw;
