import { useRef } from "react";
import { colors } from "../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../Text";
import { formatCurrencyNoDecimal, getMonthEN } from "../../tools";

const textDecider = ({ labels, data, index }) => {
  const { date } = labels[index] || {};

  const monthNumber = new Date(date).getMonth() + 1;
  const month = getMonthEN(monthNumber == 0 ? 12 : monthNumber);
  const year = new Date(date).getFullYear();

  const amount = data[index];

  const top = `${month} ${year}`;
  const bottom = `IDR ${formatCurrencyNoDecimal(amount)}`;

  return { top, bottom };
};

export const AreaChartTooltip = ({ tooltipProps, height, labels, data }) => {
  const { x, y, index } = tooltipProps || {};

  const ref = useRef();

  const { clientHeight = 52, clientWidth = 100 } = ref?.current || {};

  const { bottom, top } = textDecider({ data, labels, index });

  const lineHeight = height - y - 32;

  if (!tooltipProps) return null;

  return (
    <>
      {/* text */}
      <div
        style={{
          position: "absolute",
          left: x - clientWidth / 2,
          top: y - clientHeight - 12,
          backgroundColor: colors.blue10,
          padding: 11,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 4,
          zIndex: 1,
        }}
        ref={ref}
      >
        <GothamRegular style={{ textAlign: "center", color: "white" }}>
          {top}
        </GothamRegular>
        <GothamMedium
          style={{ textAlign: "center", color: "white", whiteSpace: "nowrap" }}
        >
          {bottom}
        </GothamMedium>
      </div>
      {/* text */}
      {/* black line */}
      {/* <div
        style={{
          position: "absolute",
          left: x,
          top: y + 4,
        }}
      >
        <div
          style={{
            height: Number(lineHeight) > 0 ? lineHeight : 0,
            borderLeft: "2px dotted black",
          }}
        />
      </div> */}
      {/* black line */}
    </>
  );
};
