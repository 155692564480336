import { useEffect } from "react";
import { RightModal } from "../RightModal/parent";
import Withdraw from "./withdraw";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  DEMO_CLICK_BACK,
  DEMO_PAGE_VIEW,
} from "../../../pageComponents/productDemoComponents/constants/events";

const WithdrawalModal = ({ isOpen = false, toggle = () => {} }) => {
  if (!isOpen) return null;
  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, { page_title: "withdraw" });
  }, []);
  const onToggleWithEvents = () => {
    if (isOpen) {
      eventsTracker(DEMO_CLICK_BACK, { page_title: "withdraw" });
    }
    toggle();
  };
  return <RightModal isOpen={isOpen} toggle={onToggleWithEvents} array={[<Withdraw />]} />;
};

export default WithdrawalModal;
