import { titleCase } from "change-case";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { jackColors } from "../../assets/colors";
import { AreaChart } from "../../components/Chart/AreaChart/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { customSum, shortMonth } from "../../components/tools";
import { fetch } from "../../tools/api";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useGetAuth } from "../../contexts/AuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const spendingFormatter = (res) => {
  const { data: array } = res;
  let labels = [];
  let data = [];

  array?.forEach(({ date, amount }) => {
    const getDate = new Date(date);
    const month = getDate.getMonth();
    const shortedMonth = shortMonth(month + 1);

    labels.push({ value: shortedMonth, date });
    data.push(Number(amount));
  });

  return { labels, data };
};

const numberFormatter = (number) => {
  const k = 1000;
  const m = k * k;
  const b = m * k;

  const obj = { k, m, b };

  const boolean = (divider) => number / divider > 1;

  const getResult = (divider) => {
    const keys = Object.keys(obj);

    const unit = titleCase(
      keys.filter((key) => {
        const value = obj[key];
        return value == divider;
      })[0]
    );

    return `${Math.ceil(number / divider)}${unit}`;
  };

  if (boolean(b)) return getResult(b);
  if (boolean(m)) return getResult(m);
  if (boolean(k)) return getResult(k);
  return number;
};

const spendingFunc = (arrayRaw) => {
  const array = arrayRaw || [0];
  const amount = customSum(array);
  return numberFormatter(amount);
};

export const DashboardChart = () => {
  const { user } = useGetAuth();
  const { data: spending, refetch } = fetch({
    url: "/my_company_spending",
    formatter: spendingFormatter,
    defaultValue: {},
    woInit: true,
  });

  const { data } = spending;
  const totalSpending = `IDR ${spendingFunc(data)}`;

  const isLoggedIn = !isEmpty(user);

  useEffect(() => {
    if (isLoggedIn) refetch();
  }, [isLoggedIn]);

  return (
    <div
      style={{
        padding: 32,
        borderBottom: `1px solid ${jackColors.greyE6}`,
      }}
    >
      <div style={{ marginBottom: 24 }}>
        <GothamRegular
          style={{ marginBottom: 4, color: jackColors.grey6C }}
          className="font12"
        >
          Company Spending
        </GothamRegular>
        <GothamMedium className="font16">{totalSpending}</GothamMedium>
      </div>
      <AreaChart {...spending} />
    </div>
  );
};
