import { jackColors } from "../../../../assets/colors";
import { GothamMedium } from "../../../../components/Text";
import styles from "../../styles.module.css";

const AlertIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 1.66699C8.35182 1.66699 6.74065 2.15573 5.37024 3.07141C3.99983 3.98709 2.93173 5.28858 2.301 6.8113C1.67027 8.33401 1.50524 10.0096 1.82678 11.6261C2.14833 13.2426 2.942 14.7274 4.10744 15.8929C5.27287 17.0583 6.75773 17.852 8.37424 18.1735C9.99075 18.4951 11.6663 18.3301 13.189 17.6993C14.7117 17.0686 16.0132 16.0005 16.9289 14.6301C17.8446 13.2597 18.3333 11.6485 18.3333 10.0003C18.3333 8.90598 18.1178 7.82234 17.699 6.8113C17.2802 5.80025 16.6664 4.88159 15.8925 4.10777C15.1187 3.33395 14.2001 2.72012 13.189 2.30133C12.178 1.88254 11.0943 1.66699 9.99999 1.66699ZM9.99999 14.167C9.83518 14.167 9.67406 14.1181 9.53702 14.0266C9.39998 13.935 9.29317 13.8048 9.23009 13.6526C9.16702 13.5003 9.15052 13.3327 9.18267 13.1711C9.21483 13.0094 9.29419 12.8609 9.41074 12.7444C9.52728 12.6279 9.67577 12.5485 9.83742 12.5163C9.99907 12.4842 10.1666 12.5007 10.3189 12.5638C10.4712 12.6268 10.6013 12.7336 10.6929 12.8707C10.7845 13.0077 10.8333 13.1688 10.8333 13.3337C10.8333 13.5547 10.7455 13.7666 10.5892 13.9229C10.433 14.0792 10.221 14.167 9.99999 14.167ZM10.8333 10.8337C10.8333 11.0547 10.7455 11.2666 10.5892 11.4229C10.433 11.5792 10.221 11.667 9.99999 11.667C9.77898 11.667 9.56702 11.5792 9.41074 11.4229C9.25446 11.2666 9.16666 11.0547 9.16666 10.8337V6.66699C9.16666 6.44598 9.25446 6.23402 9.41074 6.07774C9.56702 5.92146 9.77898 5.83366 9.99999 5.83366C10.221 5.83366 10.433 5.92146 10.5892 6.07774C10.7455 6.23402 10.8333 6.44598 10.8333 6.66699V10.8337Z"
        fill="#FCB037"
      />
    </svg>
  );
};

const SuspendedBanner = () => {
  return (
    <div className={styles["suspended-banner"]}>
      <AlertIcon />
      <GothamMedium className="font12" style={{ color: jackColors.neutral900 }}>
        Your account has been suspended. Please contact your Account Manager to
        reactivate or downgrade your plan.
      </GothamMedium>
    </div>
  );
};

export default SuspendedBanner;
