import React, { useEffect, useRef, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../../components/Modals";
import PINModal from "../../../../../components/PINModal/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { apiBusiness, fetch, useMutation } from "../../../../../tools/api";
import { DashboardCard } from "./card";
import { dashboardCardFormatter } from "./logic";
import Tooltip from "../../../../cardPageComponents/components/Tooltip";
import CustomTooltip from "../../../../../components/Tooltip";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { cardTypeDecider } from "../../../../cardPageComponents/tools";

const Bullet = ({ isActive, onClick }) => (
  <div
    style={{
      width: 8,
      borderRadius: 8,
      height: 8,
      backgroundColor: isActive ? jackColors.black34 : jackColors.greyE6,
    }}
    onClick={onClick}
    className={isActive ? "" : "hover"}
  />
);

const TextComponent = ({
  title,
  msg,
  woClassName,
  showDetails,
  copyValue,
  woMargin,
  innerComponent,
  isPhysical,
}) => {
  const [hover, setHover] = useState(false);
  const [isHoverPhysical, setIsHoverPhysical] = useState(false);

  const [copy, setCopy] = useState(false);

  useEffect(() => {
    if (!hover) return setCopy(false);
  }, [hover]);

  const TooltipDecider = ({ children }) => {
    if (isPhysical && isHoverPhysical)
      return (
        <CustomTooltip
          text={
            "See your card number, expiry date, and CVV on your physical card"
          }
          placement="left"
          style={{
            backgroundColor: "white",
            color: jackColors.black34,
            fontFamily: "GothamBook",
            boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            width: 240,
          }}
          popperClassName="white-arrow"
        >
          {children}
        </CustomTooltip>
      );
    if (!hover) return children;
    return (
      <CustomTooltip
        text={copy ? "Copied" : "Click to copy"}
        placement="left"
        style={{
          backgroundColor: "white",
          color: jackColors.black34,
          fontFamily: "GothamBook",
          boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
        }}
        popperClassName="white-arrow"
      >
        {children}
      </CustomTooltip>
    );
  };

  return (
    <div className={woClassName ? "" : "w-50"}>
      <GothamRegular
        className="font12"
        style={{
          marginTop: woMargin ? 0 : 12,
          color: jackColors.grey90,
          marginBottom: isPhysical ? 6 : 0,
        }}
      >
        {title}
      </GothamRegular>
      <TooltipDecider>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            transition: "box-shadow 0.2s ease-in-out",
            paddingBottom: "4px",
          }}
          onClick={() => {
            if (!hover && isPhysical) return;
            setCopy(true);
            navigator.clipboard.writeText(copyValue);
          }}
          onMouseEnter={() => {
            if (showDetails) setHover(true);
            if (isPhysical) {
              setIsHoverPhysical(true);
            }
          }}
          onMouseLeave={() => setHover(false)}
        >
          <GothamMedium
            style={{
              color: jackColors.black34,
              marginTop: 4,
              textDecoration: hover ? "underline" : "none",
            }}
          >
            {msg}
          </GothamMedium>
          {innerComponent}
        </div>
      </TooltipDecider>
    </div>
  );
};

export const CardsCarousel = ({ array }) => {
  const [index, setIndex] = useState(0);
  const isLast = index == array.length - 1;
  const isFirst = index == 0;

  const ref = useRef();

  const cardWidth = 260;
  const cardGap = 5;

  useEffect(() => {
    const left = index * (cardWidth + cardGap);
    ref.current?.scrollTo({ left });
  }, [index]);

  const card = array[index] || {};

  // card pin
  const { isOpen, toggle } = useModalHook();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const { id, card_type: cardType } = card;
  const { errorToasterApi } = ToasterHook();

  const submitPin = async ({ pin }, toggle) => {
    try {
      setLoading(true);
      const url = `cards/${id}/card_detail?pin=${pin}`;
      const res = await apiBusiness.get(url);
      const obj = res?.data?.data || {};
      eventsTracker("home_shows_card_info", { id });
      toggle();
      setInfo(obj);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => setInfo(false), [index]);
  // card pin

  const { cardNumber, cvv, expiryDate, expiryDateText } =
    dashboardCardFormatter(info || card);

  const color = (isDisabled) =>
    isDisabled ? jackColors.greyBB : jackColors.black34;

  const { isPhysical } = cardTypeDecider(cardType);
  const isRevealable = !isPhysical;

  return (
    <div className="d-flex">
      {/* CAROUSEL */}
      <div
        className="d-flex"
        style={{
          width: cardWidth + 5,
          overflowX: "hidden",
          scrollBehavior: "smooth",
          gap: cardGap,
        }}
        ref={ref}
      >
        {array.map((item, index) => (
          <DashboardCard {...item} key={index} />
        ))}
      </div>
      {/* CAROUSEL */}
      <div
        style={{
          marginLeft: 24,
          width: "calc(100% - 280px)",
          flexDirection: "column",
        }}
        className="d-flex justify-content-between"
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextComponent
              title="Card Number"
              msg={cardNumber}
              woClassName
              showDetails={!!info}
              copyValue={cardNumber}
              woMargin
              innerComponent={
                !info &&
                isRevealable && (
                  <ButtonJack
                    type="outline"
                    isBullet
                    leftIcon={
                      <JackIcons
                        name="visibility"
                        fill={jackColors.black34}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={toggle}
                    style={{
                      minWidth: 120,
                    }}
                  >
                    Show Info
                  </ButtonJack>
                )
              }
              isPhysical={isPhysical}
            />
          </div>

          {isRevealable && (
            <div className="d-flex justify-content-between">
              <TextComponent
                title="Exp. Date"
                msg={expiryDate}
                showDetails={!!info}
                copyValue={expiryDateText}
              />
              <TextComponent
                title="CVV"
                msg={cvv}
                showDetails={!!info}
                copyValue={cvv}
              />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center" style={{ gap: 8 }}>
          {array.map((_, i) => (
            <Bullet key={i} isActive={i == index} onClick={() => setIndex(i)} />
          ))}
          <JackIcons
            name="arrow-circle-left-outline"
            fill={color(isFirst)}
            className="hover"
            onClick={() => {
              if (isFirst) return;
              setIndex((prev) => prev - 1);
            }}
          />
          <JackIcons
            name="arrow-circle-right-outline"
            fill={color(isLast)}
            className="hover"
            onClick={() => {
              if (isLast) return;
              setIndex((prev) => prev + 1);
            }}
          />
        </div>
      </div>
      <PINModal
        isOpen={isOpen}
        toggle={toggle}
        onSubmit={submitPin}
        isLoading={loading}
      />
    </div>
  );
};
